import React from "react"
import classNames from "classnames"

interface IInputProps {
  className?: string
  inputClassName?: string
  labelClassName?: string
  id?: string
  label?: string
  type?: string
  name?: string
  value?: string
  placeholder?: string
  hiddenLabel?: boolean
  fullWidth?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = ({
  id,
  name,
  label,
  type,
  hiddenLabel,
  placeholder,
  onChange,
  className,
  inputClassName,
  labelClassName,
  fullWidth,
  disabled,
  value,
}: IInputProps) => {
  return (
    <div className={classNames(fullWidth && `w-full`, className)}>
      <label
        htmlFor={id}
        className={classNames(hiddenLabel && "sr-only", labelClassName)}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        className={classNames(
          "block rounded-md border border-mediumGrey p-1 focus:border-mediumBlue focus:ring-mediumBlue sm:text-sm",
          fullWidth && `w-full`,
          inputClassName
        )}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </div>
  )
}

export default Input
