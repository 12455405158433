import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { useScrollYPosition } from "react-use-scroll-position"
import useWindowSize from "utils/useWindowSize"
import isBrowser from "utils/isBrowser"
import { Typography } from "@material-ui/core"

import Edges from "components/edges"
import CloseIcon from "icons/close.svg"
import GetNotified from "./GetNotified"

import * as theme from "theme"

const StickyAddToCart = props => {
  const scrollY = useScrollYPosition()
  const { height } = useWindowSize()

  const scrolledToBottom = isBrowser
    ? scrollY + height === window?.document?.body?.offsetHeight
    : true

  const { title, selectedVariant, button, visible, onClose, ...rest } = props

  return (
    <Container visible={!scrolledToBottom && visible} {...rest}>
      <CloseContainer onClick={onClose}>
        <CloseIcon />
      </CloseContainer>

      <Edges>
        <Inner>
          <Typography
            variant="h3"
            gutterBottom
            css={css`
              margin-right: 10px;
            `}
          >
            {title}
          </Typography>

          <Controls>
            <Options>
              {selectedVariant?.selectedOptions &&
                selectedVariant.selectedOptions.map((option, i) => {
                  if (option?.name === "Color") {
                    return (
                      <Option
                        key={i}
                        lastitem={
                          selectedVariant?.selectedOptions?.length === i + 1
                        }
                        to="#product-details"
                      >
                        <Typography
                          variant="subtitle2"
                          css={css`
                            margin-right: 10px;
                          `}
                        >
                          {option.name}
                        </Typography>
                        <ImageContainer>
                          <img
                            src={selectedVariant?.image?.originalSrc}
                            alt=""
                          />
                        </ImageContainer>
                      </Option>
                    )
                  } else {
                    return (
                      <Option
                        key={i}
                        to="#product-details"
                        lastitem={
                          selectedVariant?.selectedOptions?.length === i + 1
                        }
                      >
                        <Typography variant="subtitle2">
                          {option.name} {option.value}
                        </Typography>
                      </Option>
                    )
                  }
                })}
            </Options>
            {button && <ButtonContainer>{button}</ButtonContainer>}
          </Controls>
        </Inner>
      </Edges>
    </Container>
  )
}

export default StickyAddToCart

const Container = styled.div`
  border-top: 1px solid #e5e5e5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  padding: 20px 0;
  background: #fff;
  transition: ease all 0.4s;

  @media (min-width: 768px) {
    padding: 30px 0;
  }

  h3 {
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          transform: translateY(100%);
        `}
`

const CloseContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  padding: 5px;
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`
const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`
const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Option = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;

  @media (max-width: 767px) {
    ${({ lastitem }) =>
      !lastitem &&
      css`
        border-right: 1px solid #e5e5e5;
      `}
  }
  @media (min-width: 768px) {
    border-right: 1px solid #e5e5e5;
  }
`
const ImageContainer = styled.div`
  width: 30px;

  @media (min-width: 768px) {
    width: 50px;
  }

  > img {
    width: 100%;
    object-fit: contain;
    border: 1px solid ${theme.colors.primary};
  }
`
const ButtonContainer = styled.div`
  margin: 20px 0 0;

  width: 100%;
  .sticky-add-to-cart-button {
    width: 100%;
  }
  @media (min-width: 768px) {
    margin: 10px 0;

    width: auto;
    .sticky-add-to-cart-button {
      width: auto;
    }
  }
`
