import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PhotoProvider, PhotoConsumer } from "react-photo-view"
import "react-photo-view/dist/index.css"

import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"

const ImageGallery = props => {
  const { images, ...rest } = props

  return (
    <Container {...rest}>
      <PhotoProvider>
        {images &&
          images.map((image, index) => {
            const gatsbyImage = image && getImage(image.gatsbyImageData)
            return (
              <PhotoConsumer key={image.id} src={image?.originalSrc}>
                <div>
                  <ImageBlock>
                    {gatsbyImage && (
                      <GatsbyImage
                        image={gatsbyImage}
                        alt={image?.altText || ""}
                      />
                    )}
                    <ZoomOutMapIcon />
                  </ImageBlock>
                </div>
              </PhotoConsumer>
            )
          })}
      </PhotoProvider>
    </Container>
  )
}

export default ImageGallery

const Container = styled.div``

const ImageBlock = styled.div`
  width: 100%;
  cursor: pointer;
  position: relative;

  svg {
    width: 38px;
    height: 38px;
    transition: all 150ms ease-in-out;
    left: calc(50% - 19px);
    top: calc(50% - 19px);
    position: absolute;
    z-index: 2;
    color: white;
    opacity: 0;
  }
  &:hover {
    svg {
      opacity: 1;
    }
    &:before {
      background-image: rgba(0, 0, 0, 0.4);
      opacity: 0.55;
    }
  }
  &:before {
    z-index: 1;
    transition: all 150ms ease-in-out;
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
