import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
// import { Checkmark } from "react-checkmark"
import Parser from "html-react-parser"

import { TextField, Typography } from "@material-ui/core"

import Button from "components/button"
import useShopify from "shopify"
import useShopSettings from "./useShopSettings"

// import * as theme from "theme"

const FrontRow = props => {
  const { ...rest } = props

  const {
    frontRowAdditionalText,
    // frontRowSuccessMessage,
    frontRowInvalidCodeMessage,
    frontRowGenericErrorMessage,
    frontRowInputPlaceholderText,
  } = useShopSettings()

  const [open, setOpen] = useState(false)

  const [state, setState] = useState(`default`)

  const [checkCode, { loading }] = useMutation(VERIFY_CODE, {
    onCompleted: data => {
      if (data?.verifyFrontRowCode?.isValid) {
        // on valid code, add to checkout attributes
        addCheckoutAttributes([
          {
            key: "frontRowCode",
            value: data?.verifyFrontRowCode?.frontRowCode,
          },
        ])

        setState(`success`)
      } else {
        setState(`invalid_code`)
      }
    },
    onError: () => {
      setState(`something_went_wrong`)
    },
  })

  const { handleSubmit, register } = useForm()

  const { checkout, addCheckoutAttributes } = useShopify()

  const frontRowAttribute =
    checkout?.customAttributes &&
    checkout.customAttributes.find(item => item.key === "frontRowCode")

  const handleClick = () => {
    setState(`default`)
    setOpen(!open)
  }

  const onSubmit = ({ code }) => {
    // verify code in WP
    checkCode({ variables: { frontRowCode: code } })
  }

  return (
    <Container {...rest}>
      {!frontRowAttribute?.value && (
        <>
          <Button
            fullWidth
            css={css`
              margin-bottom: 10px;
            `}
            variant="contained"
            onClick={handleClick}
          >
            LMH Front Row
          </Button>
          <Panel expanded={open}>
            {frontRowAdditionalText && (
              <div>{Parser(frontRowAdditionalText)}</div>
            )}

            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                required
                inputRef={register}
                name="code"
                placeholder={frontRowInputPlaceholderText || "Front row code"}
                fullWidth
                css={css`
                  margin-right: 15px;
                `}
              />
              <Button
                type="submit"
                variant="contained"
                loading={loading}
                css={css`
                  min-width: 100px;
                `}
              >
                Apply
              </Button>
            </Form>

            {state === `invalid_code` && (
              <Typography variant="caption">
                {frontRowInvalidCodeMessage && frontRowInvalidCodeMessage}
              </Typography>
            )}

            {state === `something_went_wrong` && (
              <Typography variant="caption">
                {frontRowGenericErrorMessage && frontRowGenericErrorMessage}
              </Typography>
            )}
          </Panel>
        </>
      )}
    </Container>
  )
}

export default FrontRow

const Container = styled.div``

const Panel = styled.div`
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  height: 125px;
  ${({ expanded }) =>
    !expanded
      ? css`
          height: 0;
        `
      : css``}
`

const Form = styled.form`
  display: flex;
  align-items: flex-start;
`

const VERIFY_CODE = gql`
  mutation VerifyCode($frontRowCode: String!) {
    verifyFrontRowCode(
      input: { clientMutationId: $frontRowCode, frontRowCode: $frontRowCode }
    ) {
      clientMutationId
      frontRowCode
      isValid
    }
  }
`
