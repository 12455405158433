import React from "react"
import styled from "styled-components"

import { Tooltip, IconButton } from "@material-ui/core"

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import FavoriteIcon from "@material-ui/icons/Favorite"

import { useWishlist } from "wishlist"

const WishlistButton = props => {
  const { productId, ...rest } = props

  const { wishlist, addItemToWishlist, removeItemFromWishlist } = useWishlist()

  const productInWishlist =
    wishlist?.items && wishlist?.items?.find(item => item === productId)

  const handleClick = () => {
    if (productInWishlist) {
      removeItemFromWishlist(productId)
    } else {
      addItemToWishlist(productId)
    }
  }

  return (
    <Container {...rest}>
      <Tooltip
        title={!productInWishlist ? `Add to Wishlist` : `Remove from Wishlist`}
      >
        <IconButton onClick={handleClick}>
          {!productInWishlist ? <FavoriteBorderIcon /> : <FavoriteIcon />}
        </IconButton>
      </Tooltip>
    </Container>
  )
}

export default WishlistButton

const Container = styled.div``
