import { isEqual } from "lodash"

const getSelectedVariant = ({ variants, formValues }) => {
  if (!formValues) {
    console.warn(`getSelectedVariant: formValues is undefined`)
    return null
  }

  if (!variants) {
    console.warn(`getSelectedVariant: variants is undefined`)
    return null
  }

  return (
    variants &&
    (variants?.length === 1
      ? variants?.[0]
      : variants?.find?.(variant => {
          const { selectedOptions } = variant

          const formattedValues = Object.keys(formValues).map(key => {
            return {
              name: key,
              value: formValues[key],
            }
          })

          return isEqual(selectedOptions, formattedValues)
        }))
  )
}

export default getSelectedVariant
