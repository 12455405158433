import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import classNames from "classnames"

import Button from "../../components/button/Button"
import Input from "../../tailwind/Input"

import { Link } from "gatsby"

const GetNotified = props => {
  const { product, selectedVariant } = props

  const [state, setState] = useState(`default`) // 'default', 'success', 'error'
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [email, setEmail] = useState(``)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const handleError = err => {
    setLoading(false)
    setState(`error`)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const timeZoneOffset = new Date().getTimezoneOffset() * 60
    const productId = product?.shopifyId?.replace(`gid://shopify/Product/`, ``)
    const variantId = selectedVariant.shopifyId?.replace(
      `gid://shopify/ProductVariant/`,
      ``
    )
    const queryString = `?shop=${process.env.GATSBY_SHOPIFY_SITE_URL}&notification[email]=${email}&notification[product_no]=${productId}&variant[variant_no]=${variantId}&notification[quantity_required]=1&notification[accepts_marketing]=true&notification[customer_utc_offset]=${timeZoneOffset}`

    setLoading(true)
    try {
      const res = await fetch(
        `https://app.backinstock.org/stock_notification/create.json${queryString}`
      )

      const data = await res.json()
      setResponse(data)

      if (data?.status === "OK") {
        setLoading(false)
        setState(`success`)
      } else {
        handleError(data)
      }
    } catch (err) {
      handleError(err)
    }
  }

  const motionProps = {
    initial: {
      opacity: 0,
      height: 0,
      y: -50,
    },
    animate: {
      opacity: 1,
      height: `auto`,
      y: 0,
    },
    exit: {
      opacity: 0,
      height: 0,
      y: -50,
    },
    transition: {
      type: `tween`,
      duration: 0.15,
    },
  }

  return (
    <div className="w-full">
      <Button
        variant="contained"
        onClick={() => setIsFormVisible(!isFormVisible)}
        fullWidth
        className="z-[1] mb-[23px] flex max-h-[40px] w-full items-center justify-center"
      >
        Notify Me
      </Button>

      <AnimatePresence>
        {isFormVisible && (state === `default` || state === `error`) && (
          <motion.div {...motionProps}>
            {state === `error` && response?.errors?.base && (
              <div className="pb-1">
                {response?.errors?.base?.map?.((error, index) => {
                  return <p key={index}>{error}</p>
                })}
              </div>
            )}
            {state === `error` && !response?.errors?.base && (
              <div className="pb-1">
                <p>
                  Something went wrong. Please try again later or{" "}
                  <Link
                    to="/contact-us"
                    className="underline hover:text-darkBlue"
                  >
                    contact us.
                  </Link>
                </p>
              </div>
            )}

            <form
              onSubmit={handleSubmit}
              className={classNames(
                "flex max-w-[430px] items-end justify-between pb-6 justify-center"
              )}
            >
              <Input
                fullWidth
                label="Email"
                id="Email"
                name="email"
                type="email"
                className={classNames("max-w-[220px]")}
                placeholder="you@example.com"
                onChange={e => setEmail(e.target.value)}
                value={email}
                disabled={loading}
              />

              <Button
                loading={loading}
                variant="contained"
                className="flex max-h-[40px] max-w-[100px] items-center justify-center "
                type="submit"
              >
                Submit
              </Button>
            </form>
            <div className="mb-2">
              *By entering your email address, you consent to receiving our
              newsletter with access to our latest collections and initiatives.
              More details are provided in our{" "}
              <Link to={"/privacy-policy"}>Privacy Policy.</Link>
            </div>
          </motion.div>
        )}

        {isFormVisible && state === `success` && (
          <motion.div {...motionProps}>
            {response?.status === "OK" && response?.message && (
              <p className="pb-6">{response.message}</p>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default GetNotified
