import React, { useState } from "react"
import styled, { css } from "styled-components"
import Parser from "html-react-parser"
import { useForm, Controller } from "react-hook-form"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { isEqual } from "lodash"
import { useInView } from "react-intersection-observer"

import {
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Dialog,
} from "@material-ui/core"

// app components
import Edges from "components/edges"
import Button from "components/button"
import ImageGallery from "./ImageGallery"
import StickyAddToCart from "./StickyAddToCart"
import formatCurrencyString from "utils/formatCurrencyString"
import ContentTabs from "./ContentTabs"
import useShopify from "shopify"
import config from "shopify/config"
import useShopSettings from "./useShopSettings"
import FrontRow from "./FrontRow"
import getSelectedVariant from "../../shopify/utils/getSelectedVariant"

import WishlistButton from "./WishlistButton"
import GetNotified from "./GetNotified"
import * as theme from "theme"

const Product = props => {
  const {
    title,
    product: { descriptionHtml },
    shopifyProductId,
    product,
    acfProduct,
  } = props

  const { checkout, addLineItem, getLiveVariant } = useShopify()

  const frontRowAttribute =
    checkout?.customAttributes &&
    checkout.customAttributes.find(item => item.key === "frontRowCode")

  const frProduct = acfProduct?.linkedFrProduct?.[0]

  const { variants, options, media } = !!frontRowAttribute
    ? frProduct?.product || product || {}
    : product || {}

  const images = media?.map(media => media?.image)

  const { tabs, sizeChart, shortDescription } = acfProduct || {}

  const shopSettings = useShopSettings()

  const { addToCartButtonLabels } = shopSettings || {}

  const [loading, setLoading] = useState(false)

  const defaultValues = {}
  options &&
    options.forEach(option => {
      defaultValues[option?.name] = option?.values?.[0]
    })

  const { control, watch, setValue } = useForm({ defaultValues })
  const formValues = watch()

  const selectedVariant = getSelectedVariant({ variants, formValues })

  const optionValues =
    selectedVariant?.selectedOptions &&
    selectedVariant.selectedOptions.map(
      option => option?.value && option?.name !== "Size" && option.value
    )

  const re = optionValues && new RegExp(optionValues.join("|"))

  const variantImages =
    re && images && images.filter(image => re.test(image.altText))

  const galleryImages = variantImages?.length ? variantImages : images
  const sizeChartImage = sizeChart?.localFile && getImage(sizeChart.localFile)

  const liveVariant = getLiveVariant?.({
    shopifyId: shopifyProductId,
    variantId: selectedVariant?.shopifyId,
  })

  const itemPrice =
    (liveVariant && {
      price: liveVariant?.price?.amount,
    }) ||
    selectedVariant

  const isPreorderProduct =
    selectedVariant?.availableForSale && selectedVariant?.inventoryQuantity <= 0

  const availableForSale = selectedVariant
    ? selectedVariant?.availableForSale
    : selectedVariant?.availableForSale

  const handleAddToCart = () => {
    setLoading(true)
    addLineItem({
      notification: `Added ${title} to shopping bag`,
      variant: selectedVariant,
      quantity: 1,
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    })
  }

  const [dialogOpen, setDialogOpen] = useState(false)

  const [active, setActive] = useState(true)

  const [ref, inView] = useInView()

  const cartButtonLabel = isPreorderProduct
    ? addToCartButtonLabels?.preorder
    : availableForSale
    ? addToCartButtonLabels?.default
    : addToCartButtonLabels?.soldOut

  const isFrontRowProduct = !!frProduct

  return (
    <>
      <StickyAddToCart
        title={title}
        selectedVariant={selectedVariant}
        visible={!inView && active}
        onClose={() => setActive(false)}
        button={
          !availableForSale ? (
            <>
              <Button
                className="sticky-add-to-cart-button mb-[10px]"
                loading={loading}
                variant="contained"
                onClick={handleAddToCart}
                disabled={!availableForSale}
              >
                {cartButtonLabel}
              </Button>
              <GetNotified
                product={product}
                selectedVariant={selectedVariant}
              />
            </>
          ) : (
            <Button
              className="sticky-add-to-cart-button"
              loading={loading}
              variant="contained"
              onClick={handleAddToCart}
              disabled={!availableForSale}
            >
              {cartButtonLabel}
            </Button>
          )
        }
      />
      <Edges size="lg">
        <Container frontRowBarEnabled={frontRowAttribute?.value}>
          {galleryImages && <ImageGallery images={galleryImages} />}

          <Details
            id="product-details"
            frontRowBarEnabled={frontRowAttribute?.value}
          >
            <TitleContainer>
              <Title variant="h3" component="h1" gutterBottom>
                {title}
              </Title>
            </TitleContainer>

            <div
              css={css`
                display: flex;
              `}
            >
              {shortDescription && (
                <Typography gutterBottom>{shortDescription}</Typography>
              )}

              <div
                css={css`
                  margin-left: auto;
                `}
              >
                <WishlistButton productId={product?.storefrontId} />
              </div>
            </div>

            <Price>
              <Typography
                css={css`
                  ${!!itemPrice?.compareAtPrice &&
                    css`
                      color: ${theme.colors.primary};
                      margin-right: 10px;
                    `}
                `}
                gutterBottom
              >
                {config?.defaultCurrencyCode}{" "}
                {itemPrice?.price &&
                  formatCurrencyString({
                    amount: itemPrice?.price,
                    currencyCode: config?.defaultCurrencyCode,
                  })}
              </Typography>
              {!!itemPrice?.compareAtPrice && (
                <Typography
                  css={css`
                    font-style: italic;
                    text-decoration: line-through;
                  `}
                  gutterBottom
                >
                  {itemPrice?.compareAtPrice?.currencyCode}{" "}
                  {itemPrice?.compareAtPrice?.amount &&
                    formatCurrencyString({
                      amount: itemPrice?.compareAtPrice?.amount,
                      currencyCode: itemPrice?.compareAtPrice?.currencyCode,
                    })}
                </Typography>
              )}
            </Price>

            <Controls ref={ref}>
              {variants &&
                variants.length > 1 &&
                options &&
                options.map(option => {
                  const { id, name, values } = option

                  if (name === "Color") {
                    return (
                      <OptionContainer key={id}>
                        <Typography variant="subtitle2">{name}</Typography>

                        <Typography variant="subtitle2" gutterBottom>
                          {formValues?.[name]}
                        </Typography>

                        <Controller
                          as={({ name, value, ...rest }) => (
                            <div
                              {...rest}
                              css={css`
                                display: flex;
                                flex-wrap: wrap;
                              `}
                            />
                          )}
                          control={control}
                          defaultValue={values?.[0]}
                          name={name}
                        >
                          {values &&
                            values.map((value, index) => {
                              const optionVariant =
                                variants &&
                                variants.find(variant => {
                                  const { selectedOptions } = variant

                                  return (
                                    selectedOptions &&
                                    selectedOptions
                                      .map(option => option.value)
                                      .includes(value)
                                  )
                                })

                              const gatsbyImage =
                                optionVariant?.image &&
                                getImage(optionVariant.image.gatsbyImageData)

                              return (
                                <ThumbnailButton
                                  key={index}
                                  value={value}
                                  onClick={() => setValue(name, value)}
                                  selected={formValues?.[name] === value}
                                >
                                  {gatsbyImage && (
                                    <GatsbyImage
                                      className="color-option-image"
                                      image={gatsbyImage}
                                      alt={optionVariant?.image?.altText || ""}
                                    />
                                  )}
                                </ThumbnailButton>
                              )
                            })}
                        </Controller>
                      </OptionContainer>
                    )
                  }

                  if (name === "Size") {
                    return (
                      <OptionContainer key={id}>
                        <Typography variant="subtitle2" gutterBottom>
                          {name}
                        </Typography>

                        <Controller
                          as={({ name, value, ...rest }) => (
                            <div
                              {...rest}
                              css={css`
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 10px;
                              `}
                            />
                          )}
                          control={control}
                          defaultValue={values?.[0]}
                          name={name}
                        >
                          {values &&
                            values.map((value, index) => {
                              const optionVariant =
                                variants &&
                                variants.find(variant => {
                                  const { selectedOptions } = variant

                                  const formattedValues = Object.keys(
                                    formValues
                                  ).map(key => {
                                    if (key === name) {
                                      return {
                                        name,
                                        value,
                                      }
                                    } else {
                                      return {
                                        name: key,
                                        value: formValues[key],
                                      }
                                    }
                                  })

                                  return isEqual(
                                    selectedOptions,
                                    formattedValues
                                  )
                                })

                              const isPreorderVariant =
                                optionVariant?.inventoryQuantity <= 0 &&
                                optionVariant?.availableForSale

                              const tooltipDisabled =
                                optionVariant?.availableForSale &&
                                optionVariant?.inventoryQuantity >
                                  shopSettings?.productTooltipMinimum

                              return (
                                <Tooltip
                                  key={index}
                                  title={
                                    isPreorderVariant
                                      ? `Preorder`
                                      : optionVariant?.availableForSale ===
                                        false
                                      ? `Sold out`
                                      : optionVariant?.inventoryQuantity
                                      ? `Only ${optionVariant?.inventoryQuantity} left`
                                      : `Sold out`
                                  }
                                  disableHoverListener={tooltipDisabled}
                                  disableFocusListener={tooltipDisabled}
                                  disableTouchListener={tooltipDisabled}
                                >
                                  <OptionButton
                                    key={index}
                                    onClick={() =>
                                      formValues?.[name] !== value &&
                                      setValue(name, value)
                                    }
                                    selected={formValues?.[name] === value}
                                    strikeThrough={
                                      optionVariant?.availableForSale === false
                                    }
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color="primary"
                                    >
                                      {value}
                                    </Typography>
                                  </OptionButton>
                                </Tooltip>
                              )
                            })}

                          {sizeChart && (
                            <Typography
                              variant="caption"
                              css={css`
                                cursor: pointer;
                                display: block;
                                margin-left: auto;
                                align-self: center;
                              `}
                              onClick={() => setDialogOpen(true)}
                            >
                              Size chart
                            </Typography>
                          )}
                        </Controller>
                      </OptionContainer>
                    )
                  }

                  return (
                    <OptionContainer key={id}>
                      <FormControl>
                        <InputLabel>{name}</InputLabel>

                        <Controller
                          as={Select}
                          control={control}
                          defaultValue={values?.[0]}
                          name={name}
                        >
                          {values &&
                            values.map((value, index) => {
                              return (
                                <MenuItem key={index} value={value}>
                                  {value}
                                </MenuItem>
                              )
                            })}
                        </Controller>
                      </FormControl>
                    </OptionContainer>
                  )
                })}

              {isPreorderProduct && (
                <div
                  css={css`
                    margin-top: 20px;
                  `}
                >
                  {shopSettings?.preorderText &&
                    Parser(shopSettings.preorderText)}
                </div>
              )}

              <Button
                css={css`
                  margin-bottom: 10px;
                `}
                fullWidth
                loading={loading}
                variant="contained"
                onClick={handleAddToCart}
                disabled={!availableForSale}
              >
                {cartButtonLabel}
              </Button>
              {!availableForSale && (
                <GetNotified
                  product={product}
                  selectedVariant={selectedVariant}
                />
              )}

              {isFrontRowProduct && <FrontRow />}

              {shopSettings?.freeShippingCaption && (
                <Typography variant="caption" gutterBottom>
                  {shopSettings?.freeShippingCaption}
                  {shopSettings?.shippingInfoLink && (
                    <Link to={shopSettings?.shippingInfoLink?.url}>
                      {shopSettings?.shippingInfoLink?.title}
                    </Link>
                  )}
                </Typography>
              )}
            </Controls>

            <ContentTabs tabs={tabs} content={descriptionHtml} />
          </Details>
        </Container>
      </Edges>
      <Dialog open={dialogOpen} fullWidth onClose={() => setDialogOpen(false)}>
        <SizeChartContainer>
          <Typography
            variant="h3"
            component="h4"
            gutterBottom
            css={css`
              text-align: center;
            `}
          >
            Size Chart
          </Typography>

          {sizeChartImage && (
            <GatsbyImage
              image={sizeChartImage}
              alt={sizeChart?.altText || ""}
            />
          )}
        </SizeChartContainer>
      </Dialog>
    </>
  )
}

export default Product

const Container = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    margin-bottom: 30px;
    width: 100%;
    @media (min-width: 768px) {
      width: calc(50% - 20px);
    }
  }
  ${({ frontRowBarEnabled }) =>
    frontRowBarEnabled &&
    css`
      padding-top: 20px;
    `}
`

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const Title = styled(Typography)`
  && {
    font-weight: 500;
  }
`

const Price = styled.div`
  display: flex;
`
const Controls = styled.div`
  margin-bottom: 30px;
`

const Details = styled.div`
  @media (min-width: 768px) {
    position: sticky;

    ${({ frontRowBarEnabled }) =>
      frontRowBarEnabled
        ? css`
            top: 150px;
          `
        : css`
            top: 110px;
          `}
  }
`

const OptionContainer = styled.div`
  margin-bottom: 15px;
`

const ThumbnailButton = styled.button`
  margin-bottom: 10px;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  width: 80px;
  margin-right: 10px;
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: 0;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  > .color-option-image {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    width: 100%;
    object-fit: contain;
    transition: all 0.15s ease-in;
  }

  &:hover {
    > img {
      border-color: rgba(111, 111, 111, 1);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      > .color-option-image {
        border-color: rgba(111, 111, 111, 1);
      }
    `}
`

const OptionButton = styled.button`
  margin-bottom: 10px;
  min-width: 40px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px;
  outline: 0;
  border: 0;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  border-style: solid;
  border-width: 1px;
  border-color: rgba(111, 111, 111, 0.25);

  transition: all 0.15s ease-in;

  background: transparent;

  &:hover {
    border-color: rgba(111, 111, 111, 1);
  }

  ${({ selected }) =>
    selected &&
    css`
      border-color: rgba(111, 111, 111, 1);
    `}

  ${({ strikeThrough }) =>
    strikeThrough &&
    css`
      background: url("data:image/svg+xml;utf8,<svg version='1.1' baseProfile='tiny' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='100px' height='100px' viewBox='0 0 100 100'><line fill='none' stroke='%23000' x1='100' y1='0' x2='0' y2='100' style='stroke:rgb(111,111,111); stroke-width:5' /></svg>") no-repeat scroll 0 0 / 100% 100% transparent;
      );
    `}
`

const SizeChartContainer = styled.div`
  padding: 15px;
  width: 100%;
  > img {
    width: 100%;
  }
`
