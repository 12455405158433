import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import app components
import ShopifyProduct from "components/shopifyProduct"
import FlexibleContent from "components/flexibleContent"
import { Socials } from "components"

const ProductSingle = props => {
  const {
    data: {
      product,
      product: {
        title,
        uri,
        acf: { modules },
      },
    },
  } = props

  return (
    <Container>
      <ShopifyProduct {...product} />

      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri,
          }}
        />
      )}

      <SocialsContainer>
        <Socials />
      </SocialsContainer>
    </Container>
  )
}

export default ProductSingle

const Container = styled.div``

const SocialsContainer = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
`

export const CollectionQuery = graphql`
  query ProductSingleQuery($id: String!) {
    product: wpProduct(id: { eq: $id }) {
      title
      slug
      uri
      id
      shopifyProductId
      product {
        ...ProductFragment
      }
      acfProduct {
        linkedFrProduct {
          __typename
          ... on WpProduct {
            title

            slug
            uri
            product {
              ...ProductFragment
            }
          }
        }
        shortDescription
        tabs {
          name
          text
        }
        sizeChart {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 95, width: 600, layout: CONSTRAINED)
            }
            publicURL
          }
          altText
        }
        details
        materials
      }
      acf {
        modules {
          __typename
          ... on WpProduct_Acf_Modules_TextImageColumns {
            columns
            imagePlacement
            items {
              text
              mobileImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      width: 768
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
            desktopBannerImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
            }
          }
          ... on WpProduct_Acf_Modules_CompleteTheLook {
            headline
            items {
              overrideProductImage
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              product {
                __typename
                ... on WpProduct {
                  id
                  uri
                  product {
                    ...ProductFragment
                  }
                }
              }
            }
          }
          ... on WpProduct_Acf_Modules_Accordion {
            headline
            items {
              expanded
              headline
              content
            }
          }
          ... on WpProduct_Acf_Modules_TwoImages {
            image1 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 85, layout: FULL_WIDTH)
                }
              }
            }
            image2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 85, layout: FULL_WIDTH)
                }
              }
            }
            variant
          }
          ... on WpProduct_Acf_Modules_Columns {
            size
            columns
            items {
              button {
                target
                title
                url
              }
              buttonStyle
              text
            }
          }
          ... on WpProduct_Acf_Modules_Hero {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
            }
            video {
              localFile {
                publicURL
              }
            }
          }
          ... on WpProduct_Acf_Modules_Text {
            text
            button {
              target
              title
              url
            }
            buttonStyle
          }
          ... on WpProduct_Acf_Modules_Form {
            formId
          }
          ... on WpProduct_Acf_Modules_BannerImage {
            desktopImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
            }
            mobileImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 960, quality: 90, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
