import React, { useState } from "react"
import styled, { css } from "styled-components"
import Parser from "html-react-parser"
import { Tab, Tabs } from "@material-ui/core"

const ContentTabs = props => {
  const { content, tabs, ...rest } = props

  const [state, setState] = useState(
    !!content ? "description" : tabs?.[0]?.name || null
  )

  return (
    <Container {...rest}>
      <div>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          css={css`
            border-bottom: 1px solid #e5e5e5;
            width: 100%;
          `}
          value={state}
          onChange={(_, value) => setState(value)}
        >
          {content && <Tab label="Description" value="description" />}

          {tabs &&
            tabs.map((item, index) => {
              const { name } = item
              return <Tab key={index} value={name} label={name} />
            })}
        </Tabs>
      </div>

      <TabPanel value="description" state={state} text={content} />

      {tabs &&
        tabs.map((item, index) => {
          const { name, text } = item
          return <TabPanel key={index} value={name} state={state} text={text} />
        })}
    </Container>
  )
}

export default ContentTabs

const Container = styled.div``

const TabPanel = props => {
  const { state, value, text, className, ...rest } = props

  if (state === value) {
    return (
      <React.Fragment {...rest}>
        <div className={className}>{text && Parser(text)}</div>
      </React.Fragment>
    )
  } else {
    return <React.Fragment {...rest}></React.Fragment>
  }
}
